.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10%;
}

.link-button {
  margin-top: 1.2em;
  color: #61dafb;
  cursor: pointer;
}

.scan-code-box {
  background: white;
  border-radius: 0.3125em;
  box-shadow: 0em 0.625em 0.625em 0em rgba(0, 0, 0, 0.15);
  max-width: 20em;
  padding: 2.5em;
  text-align: center;
  z-index: 1;
}

.scan-code-title {
  color: #000000;
  font-size: 1.2em;
  max-width: 17.8125em;
  padding: 0em;
  font-family: FreightSans Pro Medium, -apple-system, '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif;
  font-weight: 400;
}

.normal-button {
  border-radius: 6.25em;
  min-width: 10em;
  display: inline-block;
  font-size: 0.875em;
  line-height: 1em;
  padding: 1.2em;
  text-align: center;
  background: #61dafb;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  border: 0;
  margin-top: 1.2em;
  position: relative;
  text-decoration: none;
}

.normal-button:hover {
  transition: all 0.4s;
  background: #28d0ff;
}

.link-button:hover {
  transition: all 0.4s;
  color: #28d0ff;
}

input {
  font-weight: 400;
  border: 1px solid #61dafb;
  outline: none;
  border-radius: 5px;
  font-size: 0.875em;
  height: 100%;
  overflow: hidden;
  padding: 0.5em;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.invalid-link {
  color: #e44061;
}

.revision-details {
  font-size: 13px;
  margin-top: 1em;
  transition: all 1s;
}

.revision-details p {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}

.revision-details p span {
  color: #b6b6b6;
}

.file-detail {
  text-align: left;
  font-weight: 400;
  font-size: 0.8em;
}

.file-detail p {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}

.file-detail p span {
  color: #b6b6b6;
}

.revisions-title {
  font-weight: 400;
}

.revisions-empty {
  font-weight: 300;
  font-style: italic;
  color: #b6b6b6;
  font-size: 0.8em;
}

.version-list {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  flex-flow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.version-list span {
  margin-right: 20px;
  width: 100px;
}
